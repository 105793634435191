<template>
    <div class="court">
        <van-nav-bar left-arrow @click-left="onClickLeft" class="nav-style" title="法院系统服务费" fixed placeholder safe-area-inset-top />
        <ShowTotal :dataJson='dataJson' />
        <van-tabs class="tab-top" v-model="active">
            <van-tab title="服务明细"></van-tab>
            <van-tab title="结算记录"></van-tab>
        </van-tabs>
        <ItemList :check='active' :list='list' :showBottom="showBottom" @itemClick="linkDetail"></ItemList>
    </div>
</template>

<script>
import { button, Toast ,NavBar , Tab, Tabs } from 'vant';
import ShowTotal from '@/components/ShowTotal'
import ItemList from '@/components/ItemList'
export default {
    name:"court",
    data(){
        return{
            dataJson:{
                title:'结算总额',
                total:0,
                frequencyName:"",
                frequencyVal:"",
                numName:"共结算笔数：",
                numVal:0,
            },
            active:0,
            showBottom:1,
            list:[]
        }
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1)
        },
        linkDetail(id){
            this.$router.push({path:'/court/detail',query:{id:id}})
        },
        getCode(){
            this.list.forEach(element => {
                element.serviceTime = this.$common.CodeValue('PLATFORM_SERVICE_TIME', element.serviceTime);
                this.dataJson.total = this.$common.float_add(this.dataJson.total,element.platformFee)
            });
        },
        getInfo() {
            Toast.loading({
                duration: 0,
                message: '加载中...',
                forbidClick: true,
            });
            this.$get('/finance/api/dicsPlatformFeeCourt/info/search').then(res => {
                if (res.code === '200') {
                    this.list = res.data.records
                    this.dataJson.numVal = res.data.total
                    Toast.clear();
                    this.getCode()
                }
            })
        },
       
    },
    mounted() {
        this.getInfo()
    },
    components:{
        [Toast.name]: Toast,
        [button.name]: button,
        [Tab.name]: Tab,
        [Tabs.name]: Tabs,
        [NavBar.name]: NavBar,
        ShowTotal,
        ItemList
    }
}
</script>

<style lang="scss" scoped>
    .court{
        background: #F4F4F4;
        height: 100vh;
    }
</style>