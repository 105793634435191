<template>
    <div class="list">
        <div v-if='list.length>0'>
            <div class="item" v-for='(item,index) in list' :key='index' @click='(check == 1 || signature) && navigator(item.id)'>
                <div class="img">
                    <img src="../assets/court.png" alt="">
                </div>
                <div class="info">
                    <div class="name" :class="check == 0?'lh40':'lhn'">{{item.orgName}}</div>
                    <div class="time" v-if='check == 1'>{{item.creatorTime}}</div>
                </div>
                <div class="right">￥{{item.platformFee.formatMoney()}}<span class="symbol"  v-if='check == 1 || signature'>&#155</span></div>
                <div class="clear"></div>
                <div class="line-wrapper" v-if='check == 0'>
                    <div v-if='showBottom == 1'>
                        <div class="line">
                            <div class="l-left">服务时间</div>
                            <div class="l-right">{{item.serviceTime}}</div>
                        </div>
                        <div class="line">
                            <div class="l-left">到期时间</div>
                            <div class="l-right">{{item.serviceTimeExpire}}</div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="line">
                            <div class="l-left">成都xxxxx机构A</div>
                            <div class="l-right">50000个</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else class="no-data">
            <img src="../assets/no-data.png" alt="">
        </div>
    </div>
</template>

<script>
export default {
    name: "ItemList",
    props: {
        check: Number,
        showBottom: Number,
        signature:Boolean,
        list:Array
    },
    data(){
        return{
            listArry:[],
            plaCode:[]
        }
    },
    methods:{
        navigator(id){
            this.$emit('itemClick',id)
        },
     
    },
    mounted() {
      
    },
};
</script>